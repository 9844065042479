import { HttpError } from '@magicdoor/errors';
import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { StepError } from '~/state/mainAppState';
import { FileUploadResult, RentalApplicationErrorCode } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';

enum ValidationErrorType {
  PHONE = 'phone',
  FIRST_NAME = 'firstname',
  LAST_NAME = 'lastname',
  EMAIL = 'email',
  DATE_OF_BIRTH = 'dateofbirth',
  SSN = 'ssn',
  LANDLORD_NAME = 'landlordname',
  LANDLORD_PHONE = 'landlordphone',
  RENT = 'rent',
  MOVE_IN_DATE = 'moveindate',
  MOVE_OUT_DATE = 'moveoutdate',
  REASON_FOR_LEAVING = 'reasonforleaving',
  STREET_ADDRESS_1 = 'address.streetaddress1',
  STREET_ADDRESS_2 = 'address.streetaddress2',
  CITY = 'address.city',
  STATE = 'address.state',
  ZIP_CODE = 'address.zipcode',
  COUNTRY = 'address.country',
  NAME = 'name',
  START_DATE = 'startdate',
  POSITION = 'position',
  EMPLOYMENT_PHONE = 'phone',
  RESIDENTIAL_HISTORY = 'residentialhistory',
  EMPLOYMENT = 'employment',
}

export class SaveRentalApplicationUseCase extends MagicUseCase {
  private rentalApplicationErrors: StepError = {};
  private rentalHistoryErrors: StepError[] = [];
  private employmentHistoryErrors: StepError[] = [];

  protected runLogic = async () => {
    const application = this.getState().user.rentalApplication.application;
    if (!application || !application?.isDraft) {
      return;
    }
    let progress = 0;
    this.onProgress?.(progress);
    const files = this.getState().user.rentalApplication.application?.newFiles?.filter((file) => !file.wasUploaded);
    const delta = files ? 100.0 / (files.length + 1) : 100;
    if (files) {
      for (const file of files) {
        const result: FileUploadResult = await rentalApplicationGateway.uploadRentalApplicationFile(file);
        (application.files = application.files || []).push({
          fileId: result.fileId,
          fileUrl: result.signedUrl,
          thumbUrl: result.signedUrl,
          type: file.type,
          fileName: file.name,
          fileExtension: '',
          contentType: '',
          fileSize: 0,
        });
        progress = Math.round(progress + delta);
        file.wasUploaded = true;
        this.onProgress?.(progress);
      }
    }
    try {
      const newApplication = await rentalApplicationGateway.updateApplication(application);
      progress += delta;
      this.getState().user.rentalApplication.application = newApplication;
    } catch (error: any) {
      this.handleError(error);
    }
    this.onProgress?.(progress);
  };

  private handleError = (error: Error) => {
    if (!(error instanceof HttpError)) {
      throw error;
    }
    const errors = error.getErrors();
    if (!errors) {
      throw error;
    }
    const keys = Object.keys(errors);
    this.getState().user.rentalApplication.application?.residentialHistory?.forEach(() => {
      this.rentalHistoryErrors.push({});
    });
    this.getState().user.rentalApplication.application?.employmentHistory?.forEach(() => {
      this.employmentHistoryErrors.push({});
    });
    keys.forEach((key: string) => {
      const sanitizedKey = key.replace(/^\$\./, '').toLowerCase();
      this.handleApplicantErrors(sanitizedKey);
      const components = this.splitErrorString(sanitizedKey);
      if (components?.prefix === ValidationErrorType.RESIDENTIAL_HISTORY) {
        this.handleRentalHistoryErrors(components.index, components.suffix);
      } else if (components?.prefix === ValidationErrorType.EMPLOYMENT) {
        this.handleEmploymentErrors(components.index, components.suffix);
      }
    });

    this.getState().user.rentalApplication.errors[RentalApplicationSteps.APPLICANT_INFORMATION] = this.rentalApplicationErrors;
    this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] = this.rentalHistoryErrors;
    this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] = this.employmentHistoryErrors;
    if (this.noErrorsWereRecognized()) {
      throw error;
    }
  };

  private handleApplicantErrors = (errorType: string) => {
    switch (errorType) {
      case ValidationErrorType.PHONE: {
        this.rentalApplicationErrors.phone = RentalApplicationErrorCode.InvalidPhone;
        break;
      }
      case ValidationErrorType.FIRST_NAME: {
        this.rentalApplicationErrors.firstName = RentalApplicationErrorCode.InvalidFirstName;
        break;
      }
      case ValidationErrorType.LAST_NAME: {
        this.rentalApplicationErrors.lastName = RentalApplicationErrorCode.InvalidLastName;
        break;
      }
      case ValidationErrorType.EMAIL: {
        this.rentalApplicationErrors.email = RentalApplicationErrorCode.InvalidEmail;
        break;
      }
      case ValidationErrorType.DATE_OF_BIRTH: {
        this.rentalApplicationErrors.dateOfBirth = RentalApplicationErrorCode.InvalidDateOfBirth;
        break;
      }
      case ValidationErrorType.SSN: {
        this.rentalApplicationErrors.ssn = RentalApplicationErrorCode.InvalidSSN;
        break;
      }
    }
  };

  private handleRentalHistoryErrors = (index: number, errorType: string) => {
    const current = this.rentalHistoryErrors[index];

    switch (errorType) {
      case ValidationErrorType.LANDLORD_NAME: {
        current.landlordName = RentalApplicationErrorCode.InvalidRentalHistoryLandlordName;
        break;
      }
      case ValidationErrorType.LANDLORD_PHONE: {
        current.landlordPhone = RentalApplicationErrorCode.InvalidRentalHistoryLandlordPhone;
        break;
      }
      case ValidationErrorType.RENT: {
        current.rent = RentalApplicationErrorCode.InvalidRentalHistoryRent;
        break;
      }
      case ValidationErrorType.MOVE_IN_DATE: {
        current.moveInDate = RentalApplicationErrorCode.InvalidRentalHistoryMoveInDate;
        break;
      }
      case ValidationErrorType.MOVE_OUT_DATE: {
        current.moveOutDate = RentalApplicationErrorCode.InvalidRentalHistoryMoveOutDate;
        break;
      }
      case ValidationErrorType.REASON_FOR_LEAVING: {
        current.reasonForLeaving = RentalApplicationErrorCode.InvalidRentalHistoryReasonForLeaving;
        break;
      }
      case ValidationErrorType.STREET_ADDRESS_1: {
        current.address1 = RentalApplicationErrorCode.InvalidRentalHistoryStreetAddress1;
        break;
      }
      case ValidationErrorType.STREET_ADDRESS_2: {
        current.address2 = RentalApplicationErrorCode.InvalidRentalHistoryStreetAddress2;
        break;
      }
      case ValidationErrorType.STATE: {
        current.state = RentalApplicationErrorCode.InvalidRentalHistoryState;
        break;
      }
      case ValidationErrorType.CITY: {
        current.city = RentalApplicationErrorCode.InvalidRentalHistoryCity;
        break;
      }
      case ValidationErrorType.ZIP_CODE: {
        current.zipCode = RentalApplicationErrorCode.InvalidRentalHistoryZipCode;
        break;
      }
      case ValidationErrorType.COUNTRY: {
        current.country = RentalApplicationErrorCode.InvalidRentalHistoryCountry;
        break;
      }
    }
  };

  private handleEmploymentErrors = (index: number, errorType: string) => {
    const current = this.employmentHistoryErrors[index];

    switch (errorType) {
      case ValidationErrorType.NAME: {
        current.name = RentalApplicationErrorCode.InvalidNameError;
        break;
      }
      case ValidationErrorType.START_DATE: {
        current.startDate = RentalApplicationErrorCode.InvalidStartDateError;
        break;
      }
      case ValidationErrorType.POSITION: {
        current.position = RentalApplicationErrorCode.InvalidPositionError;
        break;
      }
      case ValidationErrorType.EMPLOYMENT_PHONE: {
        current.phone = RentalApplicationErrorCode.InvalidPhoneError;
      }
    }
  };

  private noErrorsWereRecognized = (): boolean => {
    return !this.rentalApplicationErrors.length && !this.handleRentalHistoryErrors.length && !this.employmentHistoryErrors.length;
  };

  private splitErrorString(input: string): { prefix: string; index: number; suffix: string } | undefined {
    const match = input.match(/^([^[\]]+)\[(\d+)\]\.(.+)/);
    if (match) {
      const prefix = match[1];
      const index = parseInt(match[2], 10);
      const suffix = match[3];
      return { prefix, index, suffix };
    }
    return undefined;
  }
}
